<template>
  <div class="main-body">
    <div class="card-item">
      <a-form ref="formRef" :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol" @finish="onSubmit" @finishFailed="onFinishFailed">
        <div class="order-attention">
          <p>网站版权及备案信息设置</p>
        </div>
        <a-form-item label="版权信息" name="copyright" extra="版权信息：Copyright © xxx" :rules="[{ required: true, message: '请输入版权信息!' }]">
          <a-input v-model:value="formState.copyright" placeholder="请输入版权信息" />
        </a-form-item>
        <a-form-item label="工信部备案" name="icp" extra="工信部ICP备案信息" :rules="[{ required: true, message: '请输入工信部ICP备案信息!' }]">
          <a-input v-model:value="formState.icp" placeholder="请输入工信部备案信息" />
        </a-form-item>
        <a-form-item label="备案查询" name="icp_link" extra="工信部ICP备案查询" :rules="[{ required: true, message: '请输入工信部备案查询地址!' }]">
          <a-input v-model:value="formState.icp_link" placeholder="请输入工信部备案查询地址" />
        </a-form-item>
        <a-form-item label="联系邮箱" name="email">
          <a-input v-model:value="formState.email" placeholder="请输入联系邮箱" />
        </a-form-item>
        <a-form-item label="传真" name="fax">
          <a-input v-model:value="formState.fax" placeholder="请输入传真" />
        </a-form-item>
        <a-form-item label="邮编" name="zipcode">
          <a-input v-model:value="formState.zipcode" placeholder="请输入邮编" />
        </a-form-item>
        <!-- <a-form-item label="联系电话" name="phone">
          <a-input v-model:value="formState.phone" placeholder="请输入联系电话" />
        </a-form-item> -->
        <a-form-item label="急救电话" name="poend">
          <a-input v-model:value="formState.poend" placeholder="请输入急救电话" />
        </a-form-item>
        <a-form-item label="咨询电话" name="consult">
          <a-input v-model:value="formState.consult" placeholder="请输入咨询电话" />
        </a-form-item>
        <a-form-item label="联系地址" name="address">
          <a-input v-model:value="formState.address" placeholder="请输入联系地址" />
        </a-form-item>
        <a-form-item label="联系地址" name="address2">
          <a-input v-model:value="formState.address2" placeholder="请输入联系地址" />
        </a-form-item>
         <a-form-item label="浏览次数" name="counter">
          <a-input v-model:value="formState.counter" placeholder="请输入浏览次数" />
        </a-form-item>
        <!-- <a-form-item label="微信公众号" name="wechat_qr" extra="建议尺寸：400px*400px">
          <CsImage v-model:imgOne="formState.wechat_qr" />
        </a-form-item>
        <a-form-item label="微信小程序" name="wxapp_qr" extra="建议尺寸：400px*400px">
          <CsImage v-model:imgOne="formState.wxapp_qr" />
        </a-form-item>
        <a-form-item label="移动APP" name="app_qr" extra="建议尺寸：400px*400px">
          <CsImage v-model:imgOne="formState.app_qr" />
        </a-form-item> -->

        <a-form-item :wrapper-col="{ span: 8, offset: 3 }">
          <a-button type="primary" html-type="submit">保存</a-button>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, ref } from "vue";
import { client_save_config, client_get_config } from "@/views/client/api";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
// import CsImage from "@/components/UpImage";
export default defineComponent({
  components: {
    // CsImage,
  },
  setup() {
    //获取当前应用ID
    const route = useRoute();
    const appid = route.query.appid;

    const formRef = ref();
    const formState = reactive({
      appid: appid,
      copyright: "",
      icp: "",
      icp_link:"",
      email: "",
      phone: "",
      address: "",
      address2: "",
      wechat_qr: "",
      wxapp_qr: "",
      app_qr: "",
      fax:"",
      zipcode:"",
      poend:"",
      consult:"",
      counter:10
    });

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          client_save_config(formState).then((res) => {
            if (res.status == 200) {
              message.success(res.message);
              getInfo();
            }
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    };
    const onFinishFailed = (errorInfo) => {
      console.log("Failed:", errorInfo);
    };
    const getInfo = () => {
      client_get_config({
        appid: appid,
      }).then((res) => {
        if (res.status == 200) {
          formState.copyright = res.data.setting.copyright
            ? res.data.setting.copyright
            : "";
          formState.icp = res.data.setting.icp ? res.data.setting.icp : "";
          formState.icp_link = res.data.setting.icp_link ? res.data.setting.icp_link : "";
          formState.email = res.data.setting.email
            ? res.data.setting.email
            : "";
          formState.phone = res.data.setting.phone
            ? res.data.setting.phone
            : "";
          formState.address = res.data.setting.address
            ? res.data.setting.address
            : "";
          formState.postcode = res.data.setting.postcode
            ? res.data.setting.postcode
            : "";
          formState.wechat_qr = res.data.setting.wechat_qr
            ? res.data.setting.wechat_qr
            : "";
          formState.wxapp_qr = res.data.setting.wxapp_qr
            ? res.data.setting.wxapp_qr
            : "";
          formState.app_qr = res.data.setting.app_qr
            ? res.data.setting.app_qr
            : "";

          formState.address2 = res.data.setting.address2
            ? res.data.setting.address2
            : "";
          formState.fax = res.data.setting.fax
            ? res.data.setting.fax
            : "";
          formState.zipcode = res.data.setting.zipcode
            ? res.data.setting.zipcode
            : "";
          formState.poend = res.data.setting.poend
            ? res.data.setting.poend
            : "";
          formState.consult = res.data.setting.consult
            ? res.data.setting.consult
            : "";
          formState.counter = res.data.setting.counter
            ? res.data.setting.counter
            : 0;
        }
      });
    };
    onMounted(() => {
      getInfo();
    });
    return {
      formRef,
      formState,
      onSubmit,
      onFinishFailed,
      labelCol: {
        span: 3,
      },
      wrapperCol: {
        span: 8,
      },
    };
  },
});
</script>